import {useState, useEffect} from 'react'
import axios from 'axios'

const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_PROD_URL: process.env.REACT_APP_BASE_DEV_URL

function App() {

  const [products, setProducts] = useState(null)

  useEffect(() => {
    axios.get(baseURL).then((response) => {
      setProducts(response.data);
    });
  }, [])

  if (!products) return null;

  return (
    <div>
      <h1>My product lists</h1>
      <h2>{baseURL}</h2>
      <h3>{process.env.NODE_ENV}</h3>
      <h4>รายการสินค้าของฉัน</h4>
      <p>Lorem ipsum dolor sit amet consectetur, 
        adipisicing elit. Sed consequuntur quaerat odio 
        fugit odit corrupti eaque dolorum, quas repellat autem?</p>

      {
       products.map(element => {
          return (
            <div key={element.id} >
              <h2>{element.productName}</h2>
              <p>{element.price}</p>
              <p>{element.thumbnail}</p>
            </div>
          )
        })
      }
    </div>
  );
}

export default App